import * as R from 'ramda';
import { InvoicesStatuses, WorkOrderStatus } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import {
  generateSearchQuery,
  matchQueryGenerator,
} from '../../utils/generateSearchQuery.js';

const queryGenerator = {
  invoiceStatus: matchQueryGenerator('status'),
  clientId: matchQueryGenerator('clientId'),
  serviceTypeId: matchQueryGenerator('project.serviceTypeId'),
  propertyId: matchQueryGenerator('propertyId'),
  projectId: matchQueryGenerator('documentId'),
  projectType: matchQueryGenerator('project.type'),
  supplierId: matchQueryGenerator('supplierId'),
  managerId: matchQueryGenerator('task.managerId'),
};

export const ALL_ALLOWED_INVOICE_STATUSES = [
  InvoicesStatuses.REQUESTED,
  InvoicesStatuses.RECEIVED,
  InvoicesStatuses.MANUAL_FOLLOW_UP,
];

// getOSISearchInputQuery :: PageFilters -> JSON
// PageFilters = {
//    clientId: ID
//    serviceTypeId: ID
//    managerId: ID
//    propertyId: ID
//    projectId: ID
//    projectType: String
//    invoiceStatus: String
// }
export const getOSISearchInputQuery = R.compose(
  R.over(
    R.lensPath(['bool', 'must']),
    R.compose(
      R.concat([{ match: { 'project.status': WorkOrderStatus.COMPLETED } }]),
      R.when(
        R.compose(
          R.isNil,
          R.find(
            R.pathSatisfies(R.complement(isNilOrEmpty), ['match', 'status']),
          ),
        ),
        R.concat([{ terms: { status: ALL_ALLOWED_INVOICE_STATUSES } }]),
      ),
    ),
  ),
  generateSearchQuery(queryGenerator),
);

// getESVirtualInvoiceStatusesMatch :: [String] -> ESMatchByStatus
// ESMatchByStatus = { bool: { should: [{ term: { status: String } }] } }
// eslint-disable-next-line import/no-unused-modules
export const getESVirtualInvoiceStatusesMatch = R.compose(
  R.objOf('bool'),
  R.objOf('should'),
  R.map(R.compose(R.objOf('term'), R.objOf('supplierInvoice.status'))),
);
